<template>
  <div>
    <b-card header="频率/时间换算" header-bg-variant="tmprimary" header-text-variant="white">
      <b-form inline>
        <b-input-group prepend="频率 f">
          <b-form-input id="input_g1_f" v-model="input_g1_f" type="text" />
          <b-input-group-append>
            <b-form-select id="input_g1_funit" v-model="input_g1_funit">
              <option value="1" selected>Hz</option>
              <option value="1000">kHz</option>
              <option value="1000000">MHz</option>
              <option value="1000000000">GHz</option>
              <option value="1000000000000">THz</option>
            </b-form-select>
          </b-input-group-append>
        </b-input-group>
        &nbsp;
        <b-input-group prepend="时间 t=">
          <b-form-input id="output_g1_t" v-model="output_g1_t" type="text" readonly />
          <b-input-group-append>
            <b-form-select id="input_g1_tunit" v-model="input_g1_tunit">
              <option value="0.000000000001">ps</option>
              <option value="0.000000001">ns</option>
              <option value="0.000001">μs</option>
              <option value="0.001">ms</option>
              <option value="1" selected>s</option>
              <option value="60">min</option>
              <option value="3600">h</option>
              <option value="86400">day</option>
            </b-form-select>
          </b-input-group-append>
        </b-input-group>
      </b-form>
      <hr />
      <b-form inline>
        <b-input-group prepend="时间 t=">
          <b-form-input id="input_g2_t" v-model="input_g2_t" type="text" />
          <b-input-group-append>
            <b-form-select id="input_g2_tunit" v-model="input_g2_tunit">
              <option value="0.000000000001">ps</option>
              <option value="0.000000001">ns</option>
              <option value="0.000001">μs</option>
              <option value="0.001">ms</option>
              <option value="1" selected>s</option>
              <option value="60">min</option>
              <option value="3600">h</option>
              <option value="86400">day</option>
            </b-form-select>
          </b-input-group-append>
        </b-input-group>
        &nbsp;
        <b-input-group prepend="频率 f">
          <b-form-input id="output_g2_f" v-model="output_g2_f" type="text" readonly />
          <b-input-group-append>
            <b-form-select id="input_g2_funit" v-model="input_g2_funit">
              <option value="1" selected>Hz</option>
              <option value="1000">kHz</option>
              <option value="1000000">MHz</option>
              <option value="1000000000">GHz</option>
              <option value="1000000000000">THz</option>
            </b-form-select>
          </b-input-group-append>
        </b-input-group>
      </b-form>
    </b-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      input_g1_f: "0",
      input_g1_funit: "1",
      input_g1_tunit: "1",
      input_g2_t: "0",
      input_g2_funit: "1",
      input_g2_tunit: "1",
    };
  },
  computed: {
    output_g1_t() {
      var af = Number(this.input_g1_f) * Number(this.input_g1_funit)
      var at = 1 / af
      return at / Number(this.input_g1_tunit)
    },
    output_g2_f() {
      var at = Number(this.input_g2_t) * Number(this.input_g2_tunit)
      var af = 1 / at
      return af / Number(this.input_g2_funit)
    },
  }
};
</script>